.checkbox__input {
  opacity: 0;
}

.checkbox__label {
  margin-left: 10px;
  margin-bottom: 4px;
  margin-top: 4px;
  font-size: 15px;
  position: relative;
  display: inline-block;
  line-height: 1.85;
  padding-left: 40px;
  cursor: pointer;
  user-select: none;
}

.checkbox__label::before,
.checkbox__label::after {
  position: absolute;
  content: "";

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox__label::before {
  height: 22px;
  width: 22px;
  border: 2px solid #FD7821;
  border-radius: 5px;
  left: 0px;

  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
   *to vertically center it.
   */
  top: 3px;
}

/*Checkmark of the fake checkbox*/
.checkbox:not(.half-checked) .checkbox__label::after {
  height: 6px;
  width: 11px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);

  left: 7px;
  top: 9px;
}

/*Half checked state*/
.checkbox.half-checked .checkbox__label::after {
  content: "";
  width: 14px;
  border-bottom: 2px solid #fff;
  border-radius: 10%;

  left: 4px;
  top: 13px;
}

/*Hide the checkmark by default*/
.checkbox__input + .checkbox__label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox__input:checked + .checkbox__label::after {
  content: "";
}

.checkbox.half-checked .checkbox__label::before,
.checkbox__input:checked + .checkbox__label::before {
  background-color: #FD7821;
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox__input:focus + .checkbox__label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

/* Disabled */

.checkbox__input:disabled + .checkbox__label::before {
  background-color: #b5b5b5;
  border-color: #b5b5b5;
}
