.button {
  text-align: center;
}

.button__input {
  display: block;
  text-transform: uppercase;
  background-color: #FD7821;
  transition: all .2s;
  cursor: pointer;
  color: #fff;
  padding: 12px 35px;
  border: 0;
  min-width: 130px;
  font-size: 13px;
}

.button.rounded input {
  border-radius: 10px;
}

.button.full-width input {
  width: 100%;
}

.button.disabled input {
  pointer-events: none; 
  cursor: default; 
  background-color: #909090;
}

.button__input:hover {
  background-color: #85deed;
  text-decoration: none;
  color: #fff;
}

.button.disabled input:hover {
  background-color: #909090;
}
