.dashboard__settings-items {
  background-color: #fff;
  flex-grow: 2;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-right: 50px;
  padding-left: 35px;
  overflow-y: scroll;
}

.dashboard__settings-item-content p {
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 1.3;
  font-size: 14px;
}

.dashboard__settings-item-content-header {
  font-size: 20px;
}

.dashboard__settings-subscription-code {
  font-size: 18px;
}

.dashboard__settings-item-value {
  font-size: 20px;
}

.dashboard__settings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #F4F5F8;
  padding: 15px 0;
  min-height: 85px;
}

.dashboard__settings-item .button__input {
  width: 130px;
  padding-left: 0;
  padding-right: 0;
}

.dashboard__settings-item:last-child {
  border-bottom: 1px solid #F4F5F8;
}

.dashboard__settings-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
  max-width: 75%;
  flex-grow: 2;
}

.confirm-sync-modal__body {
  margin-top: 10px;
  margin-bottom: 20px;
}

.confirm-sync-modal__actions {
  display: flex;
  justify-content: space-around;
}

.auto-enrollments__button-wrapper {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.auto-enrollments__button-wrapper .badge {
  padding: 6px 20px;
}

.auto-enrollments__form {
  margin-top: 10px;
}

.auto-enrollments__form-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.auto-enrollments__condition-header {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 15px;
}

.dashboard__settings-sync-button-wrapper {
  display: flex;
  width: 130px;
  justify-content: center;
}

.dashboard__settings-auto-enrollment-warning {
  display: flex;
  align-items: flex-end;
}
