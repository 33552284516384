.top-bar {
  display: flex;
  height: 100px;
}

.top-bar__logo {
  display: flex;
  justify-content: center;
}

.top-bar__column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-bar__column.logo {
  flex-basis: 83%;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
}

.top-bar__column.logo a {
  margin-left: 6%;
}

.top-bar__column.logo img {
  height: 78px;
}

.top-bar__column.messages {
  width: 100px;
  border-left: 2px solid #F4F5F8;
  transition: all .2s;
}

.top-bar__column.profile {
  width: 240px;
  border-left: 2px solid #F4F5F8;
  position: relative;
}

.top-bar.logo-only .top-bar__column {
  border: 0;
}

.top-bar__profile, .top-bar__messages {
  cursor: pointer;
}

.top-bar__profile {
  display: flex;
  align-items: center;
  user-select: none;
  transition: all .2s;
  width: 100%;
}

.top-bar__profile:hover {
  color: #1392BE;
}

.top-bar__profile-menu {
  display: none;
  list-style: none;
  padding: 0;
  top: 100px;
  width: 100%;
  border-top: 1px solid #F4F5F8;
  border-bottom: 1px solid #F4F5F8;
}

.top-bar__column.show-menu .top-bar__profile {
  color: #1392BE;
}

.top-bar__column.show-menu .top-bar__profile-menu {
  display: block;
  position: absolute;
}

.top-bar__profile-menu-item {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  transition: all .2s;
  background-color: #fff;
  color: inherit;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  user-select: none;
}

.top-bar__profile-menu-item:hover {
  background-color: #1392BE;
  color: #fff;
}

.top-bar__messages-link {
  color: inherit;
  transition: all .2s;
}

.top-bar__messages-link:hover {
  color: #1392BE;
}

.top-bar__profile-icon-container {
  position: relative;
}

.top-bar__profile-icon {
  margin-left: 15px;
}

.top-bar__profile-name {
  text-align: center;
  flex-grow: 2;
  display: flex;
  justify-content: center;
}

.top-bar__profile-caret {
  margin-left: 5px;
  margin-right: 15px;
  min-width: 16px;
}

.top-bar__profile-icon-container.active-indication::after {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #64CF1F;
  position: absolute;
  right: 11px;
  top: 19px;
}
