html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Work Sans', Arial, Helvetica, sans-serif;
  color: #333;
  letter-spacing: 0.75px;
}

h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.5;
}

h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
}

p {
  font-size: 15px;
  line-height: 1.2;
}

button.emphasized-link {
  color: #ED4A4B;
  cursor: pointer;
  border: 0;
  background-color: inherit;
}

button.emphasized-link:hover {
  color: #ED4A4B;
  cursor: pointer;
  text-decoration: underline;
  border: 0;
  background-color: inherit;
}

input {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  letter-spacing: 1px;
}

.modal-content {
  padding: 15px;
  border-radius: 15px;
  border: 0;
}
