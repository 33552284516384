.login-confirmation {
  overflow-y: hidden;
  height: 100%;
}

.login-confirmation__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../images/main_background_image.jpg');
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: cover;
}

.login-confirmation__header-container {
  border-bottom: 2px solid #7CD8E9;
}

.login-confirmation__header {
  text-align: center;
}

.login-confirmation__message {
  margin-top: 30px;
  margin-bottom: 40px;
  line-height: 1.5;
  text-align: center;
}

.login-confirmation__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.login-confirmation__buttons > *:nth-child(2) {
  margin-top: 10px;
}

.login-confirmation__saml-form {
  /* Invisible form that is submitted with JavaScript */
  display: none;
}

.login-confirmation__secondary-link {
  color: #1392BE;
  margin-top: 20px !important;
  font-size: 15px;
}

.login-confirmation__secondary-link:hover {
  color: #1392BE;
}
