.rl-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #F4F5F8;
  padding: 8px 10px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  white-space: nowrap;
}

.rl-badge .circle {
  width: 12px;
  height: 14px;
  background-color: black;
  display: inline-block;
  border-radius: 50%;
  margin-right: 8px;
}

.rl-badge.blue .circle {
  background-color: #1392BE;
}

.rl-badge.red .circle {
  background-color: #FD394F;
}

.rl-badge.gray .circle {
  background-color: #BDBDBD;
}

.rl-badge.yellow .circle {
  background-color: #FFD300;
}
