.radio__input {
  opacity: 0;
}

.radio__label {
  margin-left: 10px;
  margin-bottom: 4px;
  margin-top: 4px;
  font-size: 15px;
  position: relative;
  display: inline-block;
  line-height: 1.85;
  padding-left: 40px;
  cursor: pointer;
  user-select: none;
}

.radio__label::before,
.radio__label::after {
  position: absolute;
  content: "";

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer circle of the fake radio*/
.radio__label::before {
  height: 22px;
  width: 22px;
  border: 2px solid #FD7821;
  border-radius: 50%;
  left: 0px;

  top: 3px;
}

/*Dot of the fake radio*/
.radio__label::after {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #fff;

  left: 8px;
  top: 11px;
}

/*Hide the checkmark by default*/
.radio__input + .radio__label::after {
  content: none;
}

/*Unhide on the checked state*/
.radio__input:checked + .radio__label::after {
  content: "";
}

.radio__input:checked + .radio__label::before {
  background-color: #FD7821;
}

/*Adding focus styles on the outer-box of the fake radio*/
.radio__input:focus + .radio__label::before {
  outline: rgb(59, 153, 252) auto 5px;
}
