.dashboard__sidebar {
  width: 15%;
}

.dashboard__sidebar-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 0;
  padding-top: 20px;
  padding-bottom: 40px;
  list-style: none;
  height: 100%;
}

.dashboard__sidebar-list-item {
  display: flex;
  align-items: center;
}

.dashboard__sidebar-list-item-link {
  width: 100%;
  padding: 10px 25px;
  color: inherit;
  position: relative;
}

.dashboard__sidebar-list-item-link:hover {
  text-decoration: none;
  color: #1392BE;
}

.dashboard__sidebar-list-item.alert-indication::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #EC3335;
  position: absolute;
  right: 20px;
  top: 17px;
}

.dashboard__sidebar-list-item.main,
.dashboard__sidebar-list-item.settings {
  user-select: none;
}

.dashboard__sidebar-list-item.main:hover,
.dashboard__sidebar-list-item.settings:hover {
  background-image: linear-gradient(to right, #EEF4F9, #fff);
  transition: .2s all;
}

.dashboard__sidebar-list-item.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 2;
  padding-top: 100px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
}

.dashboard__sidebar-list-item.contact h3 {
  font-size: 17px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;
}

.dashboard__sidebar-list-item.contact p {
  text-align: center;
  font-size: 14px;
  width: 80%;
  line-height: 1.3;
}

.dashboard__sidebar-list-item-link.active {
  background-image: linear-gradient(to right, #EEF4F9, #fff);
  color: #1392BE;
}

.dashboard__sidebar-list-item-link.active::before,
.dashboard__sidebar-list-item-link:hover::before {
  position: absolute;
  content: '';
  background-color: #ACD2EC;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.dashboard__sidebar-icon {
  margin-right: 10px;
  margin-bottom: 3px;
}
