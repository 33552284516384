.dashboard__account-items {
  background-color: #fff;
  flex-grow: 2;
  padding: 35px;
  overflow-y: scroll;
}

.dashboard__account-subscription-status {
  font-size: 20px;
}

.dashboard__account-subscription-status em {
  font-weight: bold;
  font-style: normal;
}

.dashboard__account-subscription-status em.active {
  color: #28a745;
}

.dashboard__account-subscription-status em.inactive {
  color: #dc3545;
}

.dashboard__account-items .dashboard__settings-item:first-child {
  border-top: 0;
}
