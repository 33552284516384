.dashboard__modal-header {
  font-size: 24px;
  margin-top: 10px;
  margin-left: 20px;
}

.dashboard__modal-subheader {
  font-size: 14px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 20px;
}

.dashboard__modal-subheader svg {
  margin-top: -5px;
  margin-right: 10px;
}

.dashboard__modal-list {
  list-style: none;
  background-color: #F0F0F0;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 25px;
}

.dashboard__modal-list-group-header {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -20px;
}

.dashboard__modal-list-group-header svg {
  margin-left: 10px;
  margin-bottom: 2px;
  cursor: pointer;
}

.dashboard__modal-list-item {
  display: flex;
  justify-content: space-between;
  margin-left: -20px;
}

.dashboard__modal-list-item.indented {
  padding-left: 30px;
}

.dashboard__modal-list-item.indented:last-child {
  margin-bottom: 20px;
}

.dashboard__modal-submitted-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 15px;
}

.dashboard__modal-submitted-message {
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: center;
}

.dashboard__modal-available-subscription-users {
  margin-left: 25px;
  margin-bottom: 5px;
}
