.global-alert {
  display: flex;
  position: fixed;
  width: 100%;
}

.global-alert__message {
  text-align: center;
  margin: auto;
  padding-left: 50px;
  padding-right: 50px;
}
