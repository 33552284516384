.button.with-spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button .spinner {
  margin-left: 10px;
}

.button__spinner-container {
  position: relative;
}

.button__spinner-container .spinner {
  position: absolute;
}
