.login {
  overflow-y: hidden;
  height: 100%;
}

.login__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../../images/main_background_image.jpg');
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: cover;
}

.login__form {
  margin-bottom: 20px;
  width: 270px;
}

.login__box-header-container {
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 2px solid #7CD8E9;
}

.login__box-header {
  margin-bottom: 20px;
}

.login__box-paragraph {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
  line-height: 1.5;
}

.login__submit-section .button {
  width: 170px;
  margin-left: 69px;
}

.login__error {
  margin-top: 20px;
  color: #ED4A4B;
}

.login__adp-button-container {
  display: flex;
  justify-content: center;
}
