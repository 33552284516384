.text-input {
  margin-bottom: 20px;
}

.text-input__label {
  margin-bottom: 5px;
  font-size: 15px;
}

.text-input__input {
  height: 50px;
  width: 270px;
  border: 1px solid rgb(221, 221, 221);
  background-color: #fbfbfb;
  padding: 0 15px;
}

.search-input__input {
  height: 40px;
  width: 100%;
  max-width: 600px;
  background-color: #F4F5F8;
  padding: 0 15px;
  border: 0;
  border-radius: 5px;
  font-size: 15px;
}

.search-input__input::placeholder {
  color: #A4A5A5;
  font-weight: 300;
}
