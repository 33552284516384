.dashboard {
  height: calc(100% - 100px);
}

.dashboard__flex-container {
  display: flex;
  height: 100%;
}

.dashboard__content {
  display: flex;
  flex-basis: 100%;
  height: 100%;
  background-color: #F4F5F8;
  padding: 25px 25px;
}

.dashboard__main {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.dashboard__main p {
  line-height: 1.5;
}

.dashboard__header {
  margin-top: 12px;
  margin-bottom: 10px;
}

.dashboard__main-subheader {
  margin-bottom: 10px;
}

.dashboard__settings {
  width: 70%;
  min-width: 1000px;
}

.dashboard__settings,
.dashboard__users,
.dashboard__enrollments {
  border-radius: 5px;
}
