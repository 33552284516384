.dashboard__content.setup-wizard {
  overflow-y: scroll;
  padding-bottom: 50px;
}

.dashboard__wizard {
  width: 70%;
  min-width: 1000px;
  border-radius: 5px;
  min-height: 820px;
}

.dashboard__wizard-steps {
  background-color: #fff;
  padding-right: 35px;
  padding-left: 35px;
  padding-top: 10px;
}

.dashboard__wizard-step {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 0px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #F4F5F8;
}

.dashboard__wizard-step.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.dashboard__wizard-step-content {
  display: flex;
  flex-basis: 75%;
  flex-direction: column;
}

.dashboard__wizard-step-content p {
  margin-bottom: 2px;
}

.dashboard__wizard-step-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.dashboard__wizard-step-cta {
  display: flex;
  flex-basis: 20%;
  align-items: flex-start;
  justify-content: center;
  margin-left: 30px;
}

.dashboard__wizard-step-position {
  display: flex;
  flex-basis: 10%;
}

.dashboard__wizard-step-position i {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border: 2px solid #1392BE;
  color: #1392BE;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
  font-style: normal;
}

.dashboard__wizard-step-info {
  font-size: 14px;
  margin-top: 20px;
}
