.box-container {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  width: 500px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 45px;
  padding-right: 45px;
  background: #fff;
  margin-bottom: 15%;
}
