.dashboard__enrollments {
  background-color: #fff;
  flex-grow: 2;
  padding-top: 15px;
  padding-right: 0px;
  padding-left: 0px;
  height: 80%;
}


.dashboard__enrollments-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.dashboard__enrollments-no-results {
  margin-top: 20px;
  margin-left: 20px;
}

.dashboard__enrollments-top-section .search-input {
  flex-basis: 30%;
}

.dashboard__enrollments-header {
  margin-bottom: 0;
}

.dashboard__enrollments {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.dashboard__enrollments-table-header,
.dashboard__enrollments-table-row {
  display: flex;
  padding: 15px 20px;
}

.dashboard__enrollments-table-header-cell,
.dashboard__enrollments-table-cell {
  word-break: break-all;
  padding-left: 2px;
  padding-right: 5px;
}

.dashboard__enrollments-table-header-cell:nth-child(1),
.dashboard__enrollments-table-cell:nth-child(1) {
  flex-basis: 25%;
  padding-left: 0;
}

.dashboard__enrollments-table-header-cell:nth-child(2),
.dashboard__enrollments-table-cell:nth-child(2) {
  flex-basis: 30%;
}

.dashboard__enrollments-table-header-cell:nth-child(3),
.dashboard__enrollments-table-cell:nth-child(3) {
  flex-basis: 18%;
}

.dashboard__enrollments-table-header-cell:nth-child(4),
.dashboard__enrollments-table-cell:nth-child(4) {
  flex-basis: 15%;
}

.dashboard__enrollments-table-header-cell:nth-child(5),
.dashboard__enrollments-table-cell:nth-child(5) {
  flex-basis: 12%;
  padding-right: 0;
  align-self: center;
}

.dashboard__enrollments-table-header {
  background-color: #1392BE;
  font-weight: bold;
  color: #fff;
}

.dashboard__enrollments-table-contents {
  overflow-y: scroll;
}

.dashboard__enrollments-table-row {
  border: 1px solid #F4F5F8;
  margin-top: 2px;
}

.dashboard__enrollments-table-row:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dashboard .progress {
  border-radius: .5rem;
}

.dashboard .progress-bar {
  background-color: #FD7821;
}

.dashboard .progress-bar[aria-valuenow*="100"] {
  background-color: #28a745;
}

.dashboard__users {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  background-color: #fff;
  padding: 20px;
}

.dashboard__users-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dashboard__users-header h2 {
  margin: 0;
}

.dashboard__users-number {
  font-size: 24px;
  font-weight: bold;
}

.dashboard__users-badges {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  flex-direction: column;
}

.dashboard__users-badges-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.dashboard__right-aside {
  display: flex;
  width: 30%;
  flex-direction: column;
  padding-left: 15px;
  margin-top: 70px;
}

